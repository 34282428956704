import React, { useEffect, createRef } from "react"
import Layout from "../components/layout"
import silvergraypranaav from "../img/silvergray-pranaav.jpg"
import SEOHeader from "../components/seo-header"
export default function Home() {
  return (
    <div>
      <SEOHeader
        title="Contact - Silvergray Photography by Pranaav BR"
        description="Pranaav produces unique, captivating photography, individually designed and curated for concepts and campaigns."
        pinterest="true"
      ></SEOHeader>

      <Layout>
        <div className="container" style={{ minHeight: "80vh" }}>
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8">
              <img src={silvergraypranaav} className="img-responsive"></img>
              <p style={{ color: "#9e9e9e", textAlign: "center" }}>
                <br></br> <b>Ph:</b> +91 90030 27159 | +91 95512 11125<br></br>
                <b>Email:</b> pranav@silvergray.in
                <br></br>
                <br></br>
              </p>
            </div>
            <div className="col-md-3"></div>
          </div>
        </div>
      </Layout>
    </div>
  )
}
